<template>
  <div>
    <v-tooltip
      v-if="!item.approved"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingWarehouseConcessions"
          color="green"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="modalA = true"
        >
          <v-icon>
            mdi-check-all
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para aprobar la unidad</span>
    </v-tooltip>

    <v-tooltip
      v-if="!item.approved"
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingWarehouseConcessions"
          color="red"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="modalE = true"
        >
          <v-icon>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para eliminar la unidad</span>
    </v-tooltip>

    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="fetchingProducts"
          color="light-blue darken-1"
          class="transparent-button-icon"
          icon
          v-bind="attrs"
          v-on="on"
          @click="openedEditDialog = true"
        >
          <v-icon size="25">
            mdi-magnify-plus-outline
          </v-icon>
        </v-btn>
      </template>
      <span>Presione para ver el detalle de la unidad</span>
    </v-tooltip>

    <!-- <v-btn
      color="yellow"
      class="transparent-button-icon"
      icon
    >
      <v-icon>
        mdi-pencil
      </v-icon>
    </v-btn> -->
    <sales-detail-dialog
      :id="item"
      v-model="openedEditDialog"
    />

    <!-- Confirm approve -->
    <v-dialog
      v-model="modalA"
      max-width="650"
    >
      <v-card>
        <v-card-title class="text-h2">
          ¿Seguro de querer confirmar esta unidad?
        </v-card-title>

        <v-card-text class="text-center grey--text">
          Esta accion creara un registro permanente en la aplicación, ¿Desea continuar?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="modalA = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            :loading="fetchingWarehouseConcessions"
            @click="approve"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Confirm delete -->

    <v-dialog
      v-model="modalE"
      max-width="650"
    >
      <v-card>
        <v-card-title class="text-h2">
          ¿Seguro de querer eliminar esta unidad?
        </v-card-title>

        <v-card-text class="text-center grey--text">
          Esta accion creara un registro permanente en la aplicación, ¿Desea continuar?
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="modalE = false"
          >
            Cancelar
          </v-btn>

          <v-btn
            color="green darken-1"
            text
            :loading="fetchingWarehouseConcessions"
            @click="deleteTransaction"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import SalesDetailDialog from './SalesDetailDialog.vue'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'SalesActions',
    components: {
      SalesDetailDialog,
    },
    props: {
      item: {
        type: Object,
        default () {
          return {}
        },
      },
    },
    data () {
      return {
        modalA: false,
        modalE: false,
        openedEditDialog: false,
      }
    },
    computed: {
      ...mapState([
        'fetchingProducts',
        'user',
        'actionsParentBusiness',
        'fetchingWarehouseConcessions',
        'actionWarehouse',
      ]),
    },
    methods: {
      ...mapActions([
        'fetchProducts',
        'deleteProducts',
        'activeProducts',
        'aproveWarhouseConcessionsById',
        'fetchBusinessSales',
        'deleteWarhouseConcessionsByCode',
      ]),

      async approve () {
        const form = {
          id: this.item.id,
          approvedByUserId: this.user.id,
        }

        await this.aproveWarhouseConcessionsById(form)

        this.modalA = false

        await this.fetchBusinessSales({ id: this.actionWarehouse, noApprove: true })
      },

      async deleteTransaction () {
        const form = {
          code: this.item.code,
          approvedByUserId: this.user.id,
        }

        await this.deleteWarhouseConcessionsByCode(form)

        this.modalE = false

        await this.fetchBusinessSales({ id: this.actionWarehouse, noApprove: true })
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
