<template>
  <div>
    <!-- <pre>{{ clientsSearch }}</pre> -->
    <h3
      class="grey--text"
    >
      Cliente
    </h3>
    <v-row justify="center">
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          :value="clientsSearch.ci"
          disabled
          label="Documento de identidad"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="8"
      >
        <v-text-field
          :value="clientsSearch.name + ' ' + clientsSearch.lastname"
          disabled
          label="Nombre"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          :value="clientsSearch.email"
          disabled
          label="Email"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          :value="clientsSearch.phone"
          disabled
          label="Teléfono"
          filled
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          :value="clientsSearch.direction"
          disabled
          label="Dirección"
          filled
        />
      </v-col>
    </v-row>
    <h3
      class="grey--text"
    >
      Productos
    </h3>
    <v-data-table
      :headers="headers"
      :items="warhouseMovementsDetail"
      :loading="fetchingWarehouseMovementsDetail"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    />
    <h3
      class="grey--text"
    >
      Pagos
    </h3>
    <v-data-table
      :headers="headersPayments"
      :items="salePayments"
      :loading="fetchingBusinessSales"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    />
    <h3
      v-if="saleSurplus.length > 0"
      class="grey--text"
    >
      Cambios
    </h3>
    <v-data-table
      v-if="saleSurplus.length > 0"
      :headers="headersSurplus"
      :items="saleSurplus"
      :loading="fetchingBusinessSales"
      loading-text="Cargando... Por favor, espere"
      no-data-text="No hay datos disponibles"
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        pageText: '{0}-{1} de {2}'
      }"
    />
    <!-- {{ id }} -->
    <!-- <pre>{{ (warhouseInputsDetail) }}</pre> -->
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  export default {
    name: 'SalesDetailTable',

    props: {
      id: {
        type: Object,
        default () {
          return {}
        },
      },
      dialog: {
        type: Boolean,
        default () {
          return false
        },
      },
    },

    data () {
      return {
        headers: [
          {
            text: 'Codigo del producto',
            value: 'codigo',
          },
          {
            text: 'Nombre del producto',
            value: 'description',
          },
          {
            text: 'Cantidad',
            value: 'cant',
          },
        ],

        headersPayments: [
          {
            text: 'Metodo de pago',
            value: 'paymentMethods',
          },
          {
            text: 'Moneda de pago',
            value: 'currency',
          },
          {
            text: 'Valor de la moneda de cambio al momento de la operacion',
            value: 'amountCurrencyReference',
          },
          {
            text: 'Monto',
            value: 'amount',
          },
          {
            text: 'Monto en $',
            value: 'totalAmountCurrencyReference',
          },
        ],

        headersSurplus: [
          {
            text: 'Metodo de pago',
            value: 'paymentMethods',
          },
          {
            text: 'Moneda de cambio',
            value: 'currency',
          },
          {
            text: 'Valor de la moneda de cambio al momento de la operacion',
            value: 'amountCurrencyReference',
          },
          {
            text: 'Monto',
            value: 'amount',
          },
          {
            text: 'Monto en $',
            value: 'totalAmountCurrencyReference',
          },
        ],
      }
    },

    computed: {
      ...mapState([
        'fetchingWarehouseMovementsDetail',
        'warhouseMovementsDetail',
        'fetchingBusinessSales',
        'salePayments',
        'saleSurplus',
        'clientsSearch',
        'fecthingClients',
      ]),
    },

    watch: {
      async dialog (v) {
        // // // // // // // // // // // // // // // // // // // // // // // // console.log(v)
        if (v) {
          await this.fetchClientById(this.id.clientId)
          await this.fetchWarehouseMovementsDetail({ id: this.id.id })
          await this.fetchBusinessSalesPayments({ id: this.id.id })
          await this.fetchBusinessSalesSurplus({ id: this.id.id })
        }
      },
    },

    async mounted () {
      // // // // // // // // // // // // // // // // // // // // // // // // console.log('id', this.id)
      await this.fetchClientById(this.id.clientId)
      await this.fetchWarehouseMovementsDetail({ id: this.id.id })
      await this.fetchBusinessSalesPayments({ id: this.id.id })
      await this.fetchBusinessSalesSurplus({ id: this.id.id })
    },

    methods: {
      ...mapActions([
        'fetchWarehouseMovementsDetail',
        'fetchBusinessSalesPayments',
        'fetchBusinessSalesSurplus',
        'fetchClientById',
      ]),
    },

  }
</script>
