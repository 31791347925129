<template>
  <sales-table />
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  import SalesTable from '../components/business-sales-no-approvate/SalesTable.vue'
  export default {
    name: 'BusinessSalsNoApprovate',
    components: {
      SalesTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness', 'actionWarehouse']),
    },

    async created () {
      // await this.fetchWarehouseMovements({ id: this.actionWarehouse })
      // await this.fetchWarehouseInventory({ id: this.actionWarehouse })
      await this.fetchBusinessSales({ id: this.actionWarehouse, noApprove: true })
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      await this.getWarehousesByParentId({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductClassification({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductCategory({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {
      ...mapActions([
        'fetchBusinessSales',
        // 'fetchWarehouseMovements',
        // 'fetchWarehouseInventory',
        // 'fetchProductLine',
        // 'fetchProductClassification',
        // 'fetchProductCategory',
        // 'fetchProductType',
        'fetchProducts',
        'getWarehousesByParentId',
      ]),
    },
  }
</script>
