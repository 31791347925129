var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',{staticClass:"grey--text"},[_vm._v(" Cliente ")]),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.ci,"disabled":"","label":"Documento de identidad","filled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.name + ' ' + _vm.clientsSearch.lastname,"disabled":"","label":"Nombre","filled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.email,"disabled":"","label":"Email","filled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.phone,"disabled":"","label":"Teléfono","filled":""}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"value":_vm.clientsSearch.direction,"disabled":"","label":"Dirección","filled":""}})],1)],1),_c('h3',{staticClass:"grey--text"},[_vm._v(" Productos ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.warhouseMovementsDetail,"loading":_vm.fetchingWarehouseMovementsDetail,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
      'items-per-page-text': 'Filas por página',
      pageText: '{0}-{1} de {2}'
    }}}),_c('h3',{staticClass:"grey--text"},[_vm._v(" Pagos ")]),_c('v-data-table',{attrs:{"headers":_vm.headersPayments,"items":_vm.salePayments,"loading":_vm.fetchingBusinessSales,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
      'items-per-page-text': 'Filas por página',
      pageText: '{0}-{1} de {2}'
    }}}),(_vm.saleSurplus.length > 0)?_c('h3',{staticClass:"grey--text"},[_vm._v(" Cambios ")]):_vm._e(),(_vm.saleSurplus.length > 0)?_c('v-data-table',{attrs:{"headers":_vm.headersSurplus,"items":_vm.saleSurplus,"loading":_vm.fetchingBusinessSales,"loading-text":"Cargando... Por favor, espere","no-data-text":"No hay datos disponibles","footer-props":{
      'items-per-page-text': 'Filas por página',
      pageText: '{0}-{1} de {2}'
    }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }